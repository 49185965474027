type AccessLevel = "logged-out" | "free" | "paid";

export interface NavItem {
	id?: number;
	href: string;
	title: string;
	access?: AccessLevel[];
	children?: NavItem[];
	megamenu?: boolean;
	isIndex?: boolean;
}

export const navLinks: NavItem[] = [
	{
		id: 1,
		href: "/research-feed/",
		title: "Research Feed",
		access: ["logged-out"],
	},
	{
		id: 2,
		href: "/guides/",
		title: "Guides",
		access: ["logged-out"],
	},
	{
		id: 3,
		title: "Health Topics",
		href: "/categories/",
		children: [
			{
				title: "Categories",
				href: "/categories/",
				children: [
					{
						href: "/categories/brain-health/",
						title: "Brain Health",
					},
					{
						href: "/categories/fat-loss/",
						title: "Fat Loss",
					},
					{
						href: "/categories/gut-health/",
						title: "Gut Health",
					},

					{
						href: "/categories/healthy-aging-longevity/",
						title: "Healthy Aging & Longevity",
					},
					{
						href: "/categories/muscle-gain-exercise/",
						title: "Muscle Gain & Exercise",
					},
					{
						href: "/categories/sleep/",
						title: "Sleep",
					},
					{
						href: "/categories/",
						title: "view-all",
						isIndex: true,
					},
				],
			},
			{
				title: "Conditions",
				href: "/conditions/",
				children: [
					{
						href: "/conditions/anxiety/",
						title: "Anxiety",
					},

					{
						href: "/conditions/alzheimers-disease/",
						title: "Alzheimer’s Disease",
					},
					{
						href: "/conditions/coronary-artery-disease/",
						title: "Coronary Artery Disease",
					},
					{
						href: "/conditions/depression/",
						title: "Depression",
					},
					{
						href: "/conditions/insomnia/",
						title: "Insomnia",
					},
					{
						href: "/conditions/multiple-sclerosis/",
						title: "Multiple Sclerosis",
					},
					{
						href: "/conditions/",
						title: "view-all",
						isIndex: true,
					},
				],
			},
			{
				title: "Outcomes",
				href: "/outcomes/",
				children: [
					{
						href: "/outcomes/blood-glucose/",
						title: "Blood Glucose",
					},
					{
						href: "/outcomes/blood-pressure/",
						title: "Blood Pressure",
					},
					{
						href: "/outcomes/nitric-oxide/",
						title: "Nitric Oxide",
					},
					{
						href: "/outcomes/testosterone/",
						title: "Testosterone",
					},
					{
						href: "/outcomes/",
						title: "view-all",
						isIndex: true,
					},
				],
			},
		],
		megamenu: true,
		access: ["logged-out", "free", "paid"],
	},
	{
		id: 4,
		title: "Supplements & More",
		href: "/interventions/",
		children: [
			{
				title: "Supplements",
				href: "/supplements/",
				children: [
					{
						href: "/supplements/ashwagandha/",
						title: "Ashwagandha",
					},
					{
						href: "/supplements/creatine/",
						title: "Creatine",
					},
					{
						href: "/supplements/curcumin/",
						title: "Curcumin",
					},
					{
						href: "/supplements/fish-oil/",
						title: "Fish Oil",
					},
					{
						href: "/supplements/vitamin-c/",
						title: "Vitamin C",
					},
					{
						href: "/supplements/vitamin-d/",
						title: "Vitamin D",
					},
					{
						href: "/supplements/",
						title: "More supplements",
						isIndex: true,
					},
				],
			},
			{
				title: "More Interventions",
				href: "/interventions/",
				children: [
					{
						href: "/diets/",
						title: "Diets",
					},
					{
						href: "/foods/",
						title: "Foods",
					},
					{
						href: "/other/",
						title: "Other",
					},
					{
						href: "/interventions/",
						title: "All interventions",
						isIndex: true,
					},
				],
			},
		],
		megamenu: true,
		access: ["free", "paid"],
	},
	{
		id: 5,
		title: "Supplements",
		href: "/interventions/",
		children: [
			{
				title: "Supplements",
				href: "/supplements/",
				children: [
					{
						href: "/supplements/ashwagandha/",
						title: "Ashwagandha",
					},
					{
						href: "/supplements/creatine/",
						title: "Creatine",
					},
					{
						href: "/supplements/curcumin/",
						title: "Curcumin",
					},
					{
						href: "/supplements/fish-oil/",
						title: "Fish Oil",
					},
					{
						href: "/supplements/vitamin-c/",
						title: "Vitamin C",
					},
					{
						href: "/supplements/vitamin-d/",
						title: "Vitamin D",
					},
					{
						href: "/supplements/",
						title: "More supplements",
						isIndex: true,
					},
				],
			},
			{
				title: "More Interventions",
				href: "/interventions/",
				children: [
					{
						href: "/diets/",
						title: "Diets",
					},
					{
						href: "/foods/",
						title: "Foods",
					},
					{
						href: "/other/",
						title: "Other",
					},
					{
						href: "/interventions/",
						title: "All interventions",
						isIndex: true,
					},
				],
			},
		],
		megamenu: true,
		access: ["logged-out"],
	},
	{
		id: 6,
		href: "/research-feed/",
		title: "Research Feed",
		children: [
			{
				href: "/research-feed/filter/?filter=categories&value=diets-foods",
				title: "Diet & Foods",
			},
			{
				href: "/research-feed/filter/?tag=editorspick",
				title: "Editor's Picks",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=healthy-aging-longevity",
				title: "Healthy Aging & Longevity",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=muscle-gain-exercise",
				title: "Muscle Gain & Exercise",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=sleep",
				title: "Sleep",
			},
			{
				href: "/research-feed/filter/?filter=categories&value=vitamins-minerals",
				title: "Vitamins & Minerals",
			},
			{
				href: "/research-feed/",
				title: "view-all",
				isIndex: true,
			},
		],
		access: ["paid"],
	},
	{
		id: 7,
		href: "/guides/",
		title: "Guides",
		children: [
			{
				href: "/guides/fat-loss/",
				title: "Fat Loss",
			},
			{
				href: "/guides/libido/",
				title: "Libido & Sexual Function",
			},
			{
				href: "/guides/memory-focus/",
				title: "Memory & Focus",
			},
			{
				href: "/guides/mood-depression/",
				title: "Mood & Depression",
			},
			{
				href: "/guides/protein-intake/",
				title: "Protein Intake/Calculator",
			},
			{
				href: "/guides/testosterone/",
				title: "Testosterone",
			},
			{
				href: "/guides/",
				title: "view-all",
				isIndex: true,
			},
		],
		access: ["paid"],
	},
	{
		id: 8,
		href: "/plus-info/",
		title: "Examine+",
		access: ["paid"],
	},
	{
		id: 9,
		href: "/plus/",
		title: "Examine+",
		access: ["free", "logged-out"],
	},
	{
		id: 11,
		href: "/about/",
		title: "About",
	},
	{
		id: 12,
		href: "/members/profile/",
		title: "Member's Area",
		access: ["free", "paid"],
	},
	{
		id: 13,
		href: "/login/",
		title: "Login",
		access: ["logged-out"],
	},
];

export const aboutLinks = [
	{
		name: "About us",
		href: "/about/",
	},
	{
		name: "Latest site updates",
		href: "/updates/",
	},
	{
		name: "How to use Examine",
		href: "/how-to-use/",
	},
	{
		name: "Our editorial process",
		href: "/about/editorial-policy/",
	},
	{
		name: "Contact us",
		href: "/contact/",
	},
	{
		name: "Examine App",
		href: "/how-to-add-examine-to-home-screen/",
	},
];

export const navLinksMobile = [
	{
		id: 0,
		href: "/categories/",
		name: "Categories",
	},
	{
		id: 1,
		href: "/conditions/",
		name: "Conditions",
	},
	{
		id: 2,
		href: "/interventions/",
		name: "Supplements & Interventions",
		children: [
			{
				href: "/supplements/",
				name: "Supplements",
			},

			{
				href: "/diets/",
				name: "Diets",
			},
			{
				href: "/foods/",
				name: "Foods",
			},
			{
				href: "/other/",
				name: "Other",
			},
		],
	},
	{
		id: 3,
		href: "/plus-info/",
		name: "Examine+",
		access: ["paid"],
	},
	{
		id: 4,
		href: "/plus/",
		name: "Examine+",
		access: ["free", "logged-out"],
	},
	{
		id: 5,
		href: "/",
		name: "Free weekly insights",
		access: ["free", "logged-out"],
		icon: "📥",
	},
	{
		id: 6,
		href: "/outcomes/",
		name: "Outcomes",
	},
	{
		id: 7,
		href: "/research-feed/",
		name: "Research Feed",
	},
	{
		id: 8,
		href: "/guides/",
		name: "Guides",
	},
	{
		id: 9,
		href: "/about/",
		name: "About Us",
	},
	{
		id: 10,
		href: "/members/dashboard/",
		name: "Member's Area",
		access: ["free", "paid"],
	},
	{
		id: 11,
		href: "/how-to-add-examine-to-home-screen/",
		name: "Examine App",
	},
];

export const subscriberLinks = [
	{
		href: "/plus-info/",
		name: "Subscription Benefits",
	},
	{
		href: "/updates/",
		name: "Latest Updates",
	},
	{
		href: "/guides/",
		name: "Supplement Guides",
	},
	{
		href: "/research-feed/",
		name: "Research Feed",
	},
	{
		href: "/examine-ai/",
		name: "ExamineAI",
	},
	{
		href: "/database/",
		name: "Examine Database",
	},
	{
		href: "/members/continuing-education/",
		name: "Continuing Education Credits",
	},
];

export const searchLinks = [
	{
		href: "/categories/",
		name: "Categories",
		children: [
			{
				href: "/categories/brain-health/",
				name: "Brain Health",
			},
			{
				href: "/categories/fat-loss/",
				name: "Fat Loss",
			},
			{
				href: "/categories/gut-health/",
				name: "Gut Health",
			},

			{
				href: "/categories/healthy-aging-longevity/",
				name: "Healthy Aging Longevity",
			},
			{
				href: "/categories/muscle-gain-exercise/",
				name: "Muscle Gain & Exercise",
			},
			{
				href: "/categories/sleep/",
				name: "Sleep",
			},
		],
	},
	{
		href: "/conditions/",
		name: "Conditions",
		children: [
			{
				href: "/conditions/anxiety/",
				name: "Anxiety",
			},

			{
				href: "/conditions/alzheimers-disease/",
				name: "Alzheimer’s Disease",
			},
			{
				href: "/conditions/coronary-artery-disease/",
				name: "Coronary Artery Disease",
			},
			{
				href: "/conditions/depression/",
				name: "Depression",
			},
			{
				href: "/conditions/insomnia/",
				name: "Insomnia",
			},
			{
				href: "/conditions/multiple-sclerosis/",
				name: "Multiple Sclerosis",
			},
		],
	},
	{
		href: "/interventions/",
		name: "Interventions",
		children: [
			{
				href: "/supplements/ashwagandha/",
				name: "Ashwagandha",
			},

			{
				href: "/supplements/creatine/",
				name: "Creatine",
			},
			{
				href: "/supplements/curcumin/",
				name: "Curcumin",
			},
			{
				href: "/supplements/fish-oil/",
				name: "Fish Oil",
			},
			{
				href: "/supplements/vitamin-c/",
				name: "Vitamin C",
			},
			{
				href: "/supplements/vitamin-d/",
				name: "Vitamin D",
			},
		],
	},
];

export const memberLinks = [
	{
		name: "My Research Feed",
		href: "/research-feed/",
	},
	{
		name: "Saved Pages",
		href: "/members/saved/",
	},
	{
		name: "My Collections",
		href: "/members/collections/",
	},
	{
		name: "My Notes",
		href: "/members/notes/",
	},
	{
		name: "My Profile",
		href: "/members/profile/",
	},
	{
		name: "Subscription & Purchases",
		href: "/members/membership/",
	},
	{
		name: "Receipts & Invoices",
		href: "/members/receipts/",
	},
	{
		name: "My Referrals",
		href: "/members/myreferrals/",
	},
];

export const companyMembersLink = {
	name: "Examine+ Enterprise Users",
	href: "/members/enterprise-users/",
};

"use client";

import { getCookie } from "cookies-next";
import useSWR from "swr";
import { MemberSettings, Settings } from "types";

import { swrFetcher } from "@/lib/hooks/fetch-client";

export const useSettings = () => {
	const token = getCookie("token");
	const { data, isLoading } = useSWR<Settings>(["/v1/settings", token], swrFetcher, {
		revalidateIfStale: false,
		refreshInterval: 1000 * 60 * 5,
	});

	return {
		settings: data,
		// settings: { ...data, trial: 0, sale: true, end: 1735776000 },
		isLoading,
	};
};

export const useMemberSettings = ({ condition }: { condition: boolean }) => {
	const token = getCookie("token");

	const { data, isLoading } = useSWR<MemberSettings>(
		condition ? ["/v1/members/settings", token] : null,
		swrFetcher,
		{
			revalidateIfStale: false,
			refreshInterval: 1000 * 60 * 5,
		}
	);

	return {
		settings: data,
		isLoading,
	};
};

"use client";

import { cva, type VariantProps } from "class-variance-authority";
import { AlertCircle, AlertTriangle, CheckCircle2, Info, XCircle } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

import { buttonVariants } from "./button";

export type AlertDismissType = "default" | "custom";

export type AlertVariantProps = VariantProps<typeof alertVariants>;

type AlertProps = {
	hasIcon?: boolean;
	allowDismiss?: boolean;
	dismissType?: AlertDismissType;
	id?: string;
} & React.HTMLAttributes<HTMLDivElement> &
	VariantProps<typeof alertVariants>;

type AlertContextType = {
	id: string;
	show: boolean;
	dismissType: AlertDismissType;
	close: () => void;
} | null;

const AlertContext = React.createContext<AlertContextType>(null);

export const useAlertContext = () => {
	const context = React.useContext(AlertContext);

	if (context == null) {
		throw new Error("Alert components must be wrapped in <Alert />");
	}

	return context;
};

const AlertIcons = {
	notification: Info,
	"new-info": Info,
	warning: AlertTriangle,
	success: CheckCircle2,
	error: AlertCircle,
} as const;

const alertVariants = cva("group relative rounded-lg border-l-8 py-4 pl-7 pr-9 text-gray-900", {
	variants: {
		variant: {
			default:
				"text-examine-green-900 border-examine-green-400 [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-white",
			general:
				"general bg-examine-purple-100 border-examine-purple-300 [&_.lucide-xcircle]:fill-examine-purple-300 [&_.lucide-xcircle]:stroke-examine-purple-100",
			summary:
				"summary bg-gray-100 border-examine-purple-300 [&_.lucide-xcircle]:fill-examine-purple-300 [&_.lucide-xcircle]:stroke-gray-100",
			quotation:
				"quotation border-l-4 border-info py-2 pl-4 text-primary xl:pl-6 xl:text-xl [&_.lucide-xcircle]:fill-info [&_.lucide-xcircle]:stroke-white",
			digging:
				"digging border-examine-green-400 bg-[#ebfcff] [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-[#ebfcff]",
			dd: "digging border-examine-green-400 bg-[#ebfcff] [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-[#ebfcff]",
			caution:
				"caution bg-caution-bg border-caution-border [&_.lucide-xcircle]:fill-caution-border [&_.lucide-xcircle]:stroke-caution-bg",
			tip: "tip bg-[#ffe6b9] border-[#f2ce72] text-[#222] [&_.lucide-xcircle]:fill-[#f2ce72] [&_.lucide-xcircle]:stroke-[#ffe6b9]",
			premium:
				"premium bg-main border-[#fde047] [&_.lucide-xcircle]:fill-[#fde047] [&_.lucide-xcircle]:stroke-main",
			warning:
				"border-[#F2CE72] bg-[#F1CD7833] [&_.lucide-xcircle]:fill-[#F2CE72] [&_.lucide-xcircle]:stroke-[#FFF2BE]",
			info: "border-examine-purple-500 bg-examine-purple-100 [&_.lucide-xcircle]:fill-examine-purple-500 [&_.lucide-xcircle]:stroke-examine-purple-100",
			success: "bg-examine-teal-100 border-examine-teal-500 text-examine-teal-900",
		},
		style: {
			default: "",
			// alternative style: https://www.figma.com/file/p0x0PyuO0SmY0uTjBpG3CA/Examine-Design-Files---Master-(updated---2024-01)?type=design&node-id=1746-65466&mode=design&t=BBVgkttblwOka0qv-4
			alt: "flex items-start p-4 text-sm lg:text-base rounded border text-[--text-color] border-[--border-color] bg-[--bg-color]",
		},
	},
	compoundVariants: [
		{
			variant: "info",
			style: "alt",
			class: "[--text-color:#084298] [--border-color:#b6d4fe] [--bg-color:#cfe2ff]",
		},
		{
			variant: "warning",
			style: "alt",
			class: "[--text-color:#664d03] [--border-color:#FFECB5] [--bg-color:#FFF3CD]",
		},
		{
			variant: "success",
			style: "alt",
			class: "[--text-color:#0F5132] [--border-color:#BADBCC] [--bg-color:#D1E7DD]",
			// class: "[--text-color:#0D503C] [--border-color:#4DD4AC] [--bg-color:#D2F4EA]",
		},
		{
			variant: "caution",
			style: "alt",
			class: "[--text-color:#842029] [--border-color:#F5C2C7] [--bg-color:#F8D7DA]",
		},
	],
	defaultVariants: {
		variant: "default",
		style: "default",
	},
});

const shouldShowAlert = (id: string) => {
	if (!id) {
		return true;
	}

	const seen = localStorage.getItem(id);
	if (seen) {
		return false;
	}
	return true;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
	(
		{
			className,
			variant,
			style,
			hasIcon,
			children,
			allowDismiss,
			dismissType = "default",
			id,
			...props
		},
		ref
	) => {
		const [show, setShow] = React.useState(false);

		const Icon = React.useMemo(() => AlertIcons[variant ?? "new-info"], [variant]);

		const showDefaultDismissButton = React.useMemo(
			() => allowDismiss && dismissType === "default",
			[allowDismiss, dismissType]
		);

		React.useEffect(() => {
			setShow(shouldShowAlert(id));
		}, [id]);

		const close = () => {
			if (id) {
				localStorage.setItem(id, "seen");
			}
			setShow(false);
		};

		return (
			<AlertContext.Provider value={{ id, show, dismissType, close }}>
				{show && (
					<div
						ref={ref}
						role="alert"
						className={cn(alertVariants({ variant, style }), className)}
						id={id}
						{...props}
					>
						{hasIcon && (
							<span className="mr-2">
								<Icon className="size-5 shrink-0 fill-current stroke-[var(--bg-color)]" />
							</span>
						)}
						{children}
						{showDefaultDismissButton && <AlertDismissButton />}
					</div>
				)}
			</AlertContext.Provider>
		);
	}
);
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement> & { as?: any }
>(({ className, as: Comp = "h5", ...props }, ref) => (
	<Comp
		ref={ref}
		className={cn(
			"mb-1 text-xl font-medium group-[.caution]:text-caution-text group-[.digging]:text-primary group-[.tip]:text-[#a04300]",
			className
		)}
		{...props}
	/>
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => <div ref={ref} className={cn("", className)} {...props} />);
AlertDescription.displayName = "AlertDescription";

type AlertDismissButtonProps = React.HTMLAttributes<HTMLButtonElement>;

const AlertDismissButton = React.forwardRef<HTMLButtonElement, AlertDismissButtonProps>(
	({ children, ...props }, ref) => {
		const { dismissType, close } = useAlertContext();

		const handleClickDismissButton = React.useCallback(
			(event: React.MouseEvent<HTMLButtonElement>) => {
				event.preventDefault();
				close();
			},
			[close]
		);

		return (
			<button
				{...props}
				ref={ref}
				onClick={handleClickDismissButton}
				className={cn(
					props.className,
					dismissType === "default"
						? "absolute right-2 top-2"
						: buttonVariants({ variant: "primary" })
				)}
			>
				{dismissType === "default" ? <XCircle className="size-5 hover:opacity-70" /> : children}
			</button>
		);
	}
);

AlertDismissButton.displayName = "AlertDismissButton";

export { Alert, AlertTitle, AlertDescription, AlertDismissButton };

"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import { ArrowRight, ArrowRightCircle } from "lucide-react";
import Link from "next/link";

import { AccessCheck } from "@/components/access-check";
import { ExaminePlusLink } from "@/components/cta";
import SaleWrapper from "@/components/sale-wrapper";
import { Badge } from "@/components/ui/badge";
import { buttonVariants } from "@/components/ui/button";
import { percentOff, saleLabel, saleVariant } from "@/lib/data/sales";
import { cn } from "@/lib/utils";

export default function SignUpMessageCard() {
	const bulletPoints = [
		<>
			<Badge variant="premium" size="sm" className="inline">
				New
			</Badge>{" "}
			Quickly and easily get answers with ExamineAI, our large language model trained exclusively on
			published Examine content
		</>,
		"Improve your health with actionable, evidence-based information collated from the entirety of available evidence",
		"Save time — get easy-to-read summaries of the latest evidence written, reviewed, and categorized by our expert team of MDs, RDs, PharmDs, and more",
		"Save money — buy only supplements shown to work for your specific health goals and get step-by-step instructions on how to time and take them",
		"Access Examine's efficacy-graded database of 10,000+ human trials for a big-picture view of 500+ interventions and 1,000+ health outcomes",
		"Customize your view of Examine — manage, collect, and organize pages important to you and add your private insights to any page for easy reference",
		"No ads, no sponsors, no bias — just evidence to help you be healthier",
	];

	return (
		<div className="bg-white py-10 md:pt-15 xl:pt-20">
			<div className="px-6 text-center">
				<h2 className="mx-auto max-w-3xl space-x-2">
					<span className="inline font-lora font-medium text-gray-900 lg:text-2xl xl:text-4xl">
						Achieve your <span className="capitalize">{saleVariant}</span> resolutions with the
						latest health research from Examine+
					</span>
					<SaleWrapper>
						<ExaminePlusLink
							trackDetails={{
								trackFor: "sale",
								location: `Homepage - Achieve your ...<<Examine+ ${percentOff} off>>`,
								label: `Examine+ ${percentOff} off`,
							}}
							className="inline-block align-text-bottom sm:pb-1"
							onClick={() => {
								sendGTMEvent({
									event: "homepage_click",
									label: `Examine+ ${percentOff} off`,
								});
							}}
						>
							<Badge variant="cyan">Examine+ {percentOff} off </Badge>
						</ExaminePlusLink>
					</SaleWrapper>
				</h2>
				<AccessCheck
					feature={[
						"examine_database",
						"study_summaries",
						"supplement_guides",
						"deep_dives_archive",
						"examine_ai",
					]}
					featureCheck="any"
					cta={
						<>
							<p className="mx-auto max-w-3xl px-4 pt-4 text-gray-700 lg:pt-6 lg:text-xl">
								Examine is focused on the science, not on clickbait and sensationalism. 100% of our
								revenue comes from our Examine+ membership — we accept no sponsors, sell no ad
								space, and have zero industry ties so you can trust our research analysis. <br />
								Examine+ unlocks everything on the site.
							</p>
						</>
					}
				/>
			</div>
			<div className="mx-auto mb-6 max-w-4xl p-6 lg:text-lg">
				<ul className="space-y-5">
					{bulletPoints.map((point, index) => (
						<li key={index}>
							<div className="flex items-stretch gap-x-2">
								<ArrowRightCircle strokeWidth={1} className="mt-0.5 size-5 shrink-0 lg:size-6" />
								{typeof point === "string" ? (
									<p className="inline">{point}</p>
								) : (
									<div className="inline">{point}</div>
								)}
							</div>
						</li>
					))}
				</ul>
			</div>
			<div className="inline-flex w-full px-6 text-center">
				<SaleWrapper
					cta={
						<Link
							href="/contact/"
							className={cn(
								buttonVariants(),
								"mx-auto inline-flex font-semibold lg:px-4 lg:py-2 lg:text-xl"
							)}
						>
							<span>
								Contact us for group access
								<ArrowRight size={24} className="inline" />
							</span>
						</Link>
					}
				>
					<ExaminePlusLink
						trackDetails={{
							trackFor: "sale",
							location: "HomePage - Signup for Examine+ SECTION",
							label: `Get Examine+ for ${percentOff} off during our ${saleLabel}`,
						}}
						className={cn(
							buttonVariants(),
							"mx-auto inline-flex font-semibold lg:px-4 lg:py-2 lg:text-xl"
						)}
						onClick={() => {
							sendGTMEvent({
								event: "homepage_click",
								label: `Get Examine+ for ${percentOff} off during our ${saleLabel}`,
							});
						}}
					>
						<span>
							Get Examine+ for {percentOff} off during our{" "}
							<span className="capitalize">{saleLabel}</span>
							<ArrowRight size={24} className="inline" />
						</span>
					</ExaminePlusLink>
				</SaleWrapper>
			</div>
		</div>
	);
}

"use client";

import { useSubscription } from "@lib/hooks/use-subscription";
import { getCookie } from "cookies-next";
import { useSearchParams } from "next/navigation";
import * as React from "react";
import useSWR from "swr";

import { swrFetcher } from "@/lib/hooks/fetch-client";
import { useAccess, useLegacyAccess } from "@/lib/hooks/use-access";
import { useEarlybird } from "@/lib/hooks/use-earlybird";
import { useMounted } from "@/lib/hooks/use-mounted";
import { useSettings } from "@/lib/hooks/use-settings";

interface SaleWrapperProps {
	children?: React.ReactNode;
	cta?: React.ReactNode;
	earlybirdCta?: React.ReactNode;
	monthlyPaidCta?: React.ReactNode;
	yearlyPaidCta?: React.ReactNode;
	lifetimePaidCta?: React.ReactNode;
	fallback?: React.ReactNode;
}

const VIEWS_LIST = {
	"loggedin-paid-monthly-user": "Monthly",
	"loggedin-paid-yearly-user": "Yearly",
	"loggedin-paid-lifetime-user": "Lifetime",
	"enterprise-user": "Enterprise",
	"loggedin-paid-legacy-edb": "Examine Plus (a-z reference)",
	"loggedin-paid-legacy-sg": "Examine Plus (a-z reference)",
	"loggedin-paid-legacy-lifetime": "Lifetime",
	"loggedin-paid-cancelled": "Cancelled",
	null: "Free",
};

export default function SaleWrapper({
	children,
	cta,
	earlybirdCta,
	monthlyPaidCta,
	yearlyPaidCta,
	lifetimePaidCta,
	fallback = null,
}: SaleWrapperProps) {
	const searchParams = useSearchParams();
	const { settings, isLoading: isLoadingSettings } = useSettings();
	const mounted = useMounted();
	const token = getCookie("token");

	const { isEnterpriseUser } = useSubscription();
	const { data: subscription, isLoading: isLoadingSubscription } = useSWR(
		token ? ["/v1/subscription", token] : null,
		swrFetcher
	);

	const hasAccess = useAccess(["study_summaries", "examine_database", "supplement_guides"]);
	const isLegacyUser = useLegacyAccess();
	const isEarlybird = useEarlybird();
	const isSales = settings?.sale;

	const testView = searchParams.has("test_view") ? searchParams.get("test_view") : null;
	const plan = testView ? VIEWS_LIST[testView] : subscription?.plan?.name;

	const loading = React.useMemo(
		() => !mounted || isLoadingSubscription || isLoadingSettings,
		[isLoadingSettings, isLoadingSubscription, mounted]
	);

	React.useEffect(() => {
		if (!loading) {
			document.dispatchEvent(new Event("rm:rerun")); // re-run rightmessage after loading
		}
	}, [isLoadingSettings, isLoadingSubscription, loading, mounted]);

	if (loading) return fallback;

	if (isEnterpriseUser) {
		return cta;
	}

	if (isEarlybird && earlybirdCta) {
		return earlybirdCta;
	}

	if (isSales && (plan === "Lifetime" || plan === "Supplement Guides Main")) {
		if (!lifetimePaidCta) return cta || null;
		return lifetimePaidCta;
	}

	if (isSales && plan === "Yearly") {
		if (!yearlyPaidCta) return cta || null;
		return yearlyPaidCta;
	}

	if (isSales && plan === "Monthly") {
		if (!monthlyPaidCta) return cta || null;
		return monthlyPaidCta;
	}

	if (isSales && (hasAccess || isLegacyUser)) {
		return cta || null;
	}

	if (
		!isSales ||
		isEnterpriseUser ||
		plan === "Enterprise" ||
		plan === "Examine Plus (a-z reference)" ||
		plan === "NERD - Lifetime" ||
		plan === "Examine Personalized - Lifetime"
	) {
		return cta || null;
	}

	return children;
}

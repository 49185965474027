"use client";

import { hasCookie, setCookie } from "cookies-next/client";
import { XIcon } from "lucide-react";
import Link from "next/link";
import React from "react";

import CountDownTimer from "@/components/countdown-timer";
import { ExaminePlusLink } from "@/components/cta";
import EndsTonight from "@/components/ends-tonight";
import SaleWrapper from "@/components/sale-wrapper";
import { Badge } from "@/components/ui/badge";
import { percentOff, saleLabel } from "@/lib/data/sales";
import { cn } from "@/lib/utils";

const data = {
	free: {
		label: `Get over ${percentOff} off Examine+ and unlock the latest health and nutrition research`,
		location: "hellobar ~ Free",
		badge: "Save now",
	},
	monthly: {
		label:
			"Upgrade to Yearly or Lifetime and lock in big savings. Get a prorated refund when you upgrade",
		location: "hellobar ~ Monthly Paid",
		badge: "Save now",
	},
	yearly: {
		label:
			"Upgrade to Lifetime at 30% off and keep access forever. Get a prorated refund when you upgrade",
		location: "hellobar ~ Yearly Paid",
		badge: "Save now",
	},
};

const HellobarMessage = ({ type }: { type: "free" | "monthly" | "yearly" }) => {
	const [isClosed, setIsClosed] = React.useState<boolean>(true);

	React.useEffect(() => {
		if (!hasCookie(`hide-hellobar-${type}`)) {
			setIsClosed(false);
			return;
		}
		setIsClosed(true);
	}, [type]);

	const handleClose = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.preventDefault();
		let age = 60 * 60 * 24;

		if (type === "monthly" || type === "yearly") {
			age = 60 * 60 * 24 * 30;
		}

		setIsClosed(true);
		setCookie(`hide-hellobar-${type}`, true, { maxAge: age });
	};

	if (isClosed) {
		return null;
	}

	return (
		<div
			className={cn(
				"group relative left-0 top-0 flex w-full justify-center bg-examine-green-500 p-2 text-white sm:hover:!bg-examine-purple-500"
			)}
		>
			<XIcon onClick={handleClose} className="absolute right-2 cursor-pointer" size={22} />
			<div>
				<ExaminePlusLink
					trackDetails={{
						trackFor: "sale",
						location: data[type]?.location,
						label: data[type]?.label,
					}}
					className="flex flex-wrap items-start justify-center gap-1 text-center sm:items-center"
				>
					<span className="flex flex-wrap items-start justify-center text-center sm:items-center">
						<EndsTonight
							lastDay={
								<strong>
									<span className="capitalize">{saleLabel}</span> ENDS TONIGHT
								</strong>
							}
							dayBeforeCta={
								<strong>
									<span className="capitalize">{saleLabel}</span> ENDS TOMORROW
								</strong>
							}
						>
							<strong>
								<span className="capitalize">{saleLabel}</span>
							</strong>
						</EndsTonight>
						:&nbsp;
						<span>
							<span className="underline">{data[type]?.label}</span>.
						</span>
					</span>
					<EndsTonight lastDay={<CountDownTimer />} dayBeforeCta={<CountDownTimer />} />
					&nbsp;
					{data[type]?.badge ? (
						<span className="align-top sm:inline-block">
							<Badge size="xs" className=" sm:group-hover:bg-examine-green-500">
								{data[type]?.badge}
							</Badge>
						</span>
					) : null}
				</ExaminePlusLink>
			</div>
		</div>
	);
};

const SalesHellobar = () => {
	return (
		<SaleWrapper
			monthlyPaidCta={<HellobarMessage type="monthly" />}
			yearlyPaidCta={<HellobarMessage type="yearly" />}
		>
			<HellobarMessage type="free" />
		</SaleWrapper>
	);
};

const SalesPageHellobar = () => {
	return (
		<SaleWrapper
			earlybirdCta={
				<Link
					href="#sales-pricing"
					className="group flex flex-wrap items-center justify-center bg-examine-yellow-premium px-2 py-2 text-center text-sm sm:hover:bg-primary sm:hover:text-white lg:text-base"
				>
					<strong className="flex pl-1 sm:group-hover:underline">
						Your Early Bird Exclusive Savings END TODAY
						<CountDownTimer expiryTimestamp={new Date("2024-11-26T00:00:00-05:00")} />
					</strong>
				</Link>
			}
		>
			<Link
				href="#sales-pricing"
				className="group flex flex-wrap items-center justify-center bg-examine-yellow-premium px-2 py-2 text-center text-sm sm:hover:bg-primary sm:hover:text-white lg:text-base"
			>
				<strong className="flex pl-1 sm:group-hover:underline">
					<EndsTonight
						dayBeforeCta={
							<>
								<span className="mr-1 capitalize">{saleLabel}</span> ENDS TOMORROW:Save over{" "}
								{percentOff} on our Examine+ membership!
							</>
						}
						lastDay={
							<>
								<span className="mr-1 capitalize">{saleLabel}</span> ENDS TONIGHT: Save over{" "}
								{percentOff} on our Examine+ membership!
							</>
						}
					>
						<span className="capitalize">{saleLabel}</span> : Unlock the latest health and nutrition
						research with Examine+
					</EndsTonight>
					<EndsTonight lastDay={<CountDownTimer />} dayBeforeCta={<CountDownTimer />} />
				</strong>
				<Badge
					size="xs"
					className="ml-1.5 mt-1.5 sm:mt-0 sm:group-hover:bg-examine-yellow-premium sm:group-hover:text-black"
				>
					Buy Now
				</Badge>
			</Link>
		</SaleWrapper>
	);
};

export { SalesHellobar, SalesPageHellobar };

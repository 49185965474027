"use client";

import Link from "next/link";
import * as React from "react";
import { Feature, SubscriptionInfo, User as UserType } from "types";

import { ExaminePlusLink } from "@/components/cta";
import EndsTonight from "@/components/ends-tonight";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import SearchIcon from "@/components/icons/search-icon";
import UserIcon from "@/components/icons/user";
import { LoginLink } from "@/components/login-link";
import SaleWrapper from "@/components/sale-wrapper";
import SearchDialog from "@/components/search/search-dialog";
import { Badge } from "@/components/ui/badge";
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuList,
	NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { NavItem, navLinks } from "@/lib/data/navigation";
import { cn } from "@/lib/utils";

import { ListItem } from "./list-item";
import { AboutMenu, ExaminePlusMenu, UserMenu } from "./menus";

const MegaMenu = ({ title, href, children }: NavItem) => {
	return (
		<li key={href}>
			<Link href={href} className="text-extra-gray whitespace-nowrap font-lora text-2xl font-bold">
				{title}
			</Link>
			<ul className="mt-4 space-y-1.5">
				{children.map((link) => {
					return (
						<ListItem key={link.href} href={link.href} title={link.title} isIndex={link.isIndex} />
					);
				})}
			</ul>
		</li>
	);
};

interface NavigationClickInterface {
	access: string;
	text?: string;
	link?: string;
}

const trackNavigationClick = ({ text, link }: NavigationClickInterface) => {
	window.dataLayer?.push({
		event: "navigation_click",
		text,
		link,
	});
};

interface NavigationProps extends React.HTMLAttributes<HTMLDivElement> {
	user: UserType;
	subscription?: SubscriptionInfo;
	features?: Feature[];
}

const Navigation = ({ user, subscription, features, className, ...props }: NavigationProps) => {
	const access = user && !subscription ? "free" : user && subscription ? "paid" : "logged-out";

	const menuRef = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		const handleLinkClick = (event: MouseEvent) => {
			const target = event.target;
			if (!(target instanceof HTMLElement)) return;
			const closestAnchor = target.closest<HTMLAnchorElement>("a, .search");

			if (!closestAnchor) return;

			trackNavigationClick({
				access,
				text: target.innerText,
				link: closestAnchor.href,
			});
		};

		const menuElement = menuRef.current;

		menuElement?.addEventListener("click", handleLinkClick);

		return () => {
			menuElement?.removeEventListener("click", handleLinkClick);
		};
	}, [access]);

	const getUserStatus = () => {
		if (!user) return "logged-out";
		return subscription ? "paid" : "free";
	};

	const canAccessLink = (link: (typeof navLinks)[0]) => {
		if (!link.access) return true;

		const userStatus = getUserStatus();
		return link.access.includes(userStatus);
	};

	return (
		<>
			<div
				{...props}
				className={cn("hidden items-center text-sm lg:flex xl:text-menu", className)}
				aria-label="Main Menu"
			>
				<SearchDialog {...{ subscription }}>
					<div className="search flex items-center gap-x-2 px-3 py-2 font-medium text-primary hover:text-gray-900">
						<span>Search</span>
						<SearchIcon className="h-4 w-4" />
					</div>
				</SearchDialog>
				<div ref={menuRef} className="flex items-center pr-8 text-sm xl:text-menu">
					<NavigationMenu>
						<NavigationMenuList>
							{navLinks?.map((link) => {
								if (!canAccessLink(link)) return null;

								if (link.href === "/plus-info/") {
									return <ExaminePlusMenu key={link.id + link.href} features={features} />;
								}

								if (link.href === "/plus/") {
									return <ExaminePlusCTA key={link.id + link.href} />;
								}

								if (link.href === "/about/") {
									return <AboutMenu key={link.id + link.href} />;
								}

								if (link.href === "/members/profile/") {
									return <UserMenu key={link.id + link.href} {...{ user, subscription }} />;
								}

								if (!link?.children) {
									return (
										<Link
											key={link.id}
											href={link.href}
											className="px-3 py-2 text-gray-900 hover:text-primary"
										>
											{link.title}
										</Link>
									);
								}

								return (
									<NavigationMenuItem value={link.id + link.href} key={link.id}>
										<NavigationMenuTrigger>
											<Link href={link.href}>{link.title}</Link>
										</NavigationMenuTrigger>
										<NavigationMenuContent>
											{link.megamenu ? (
												<ul className="flex gap-12 p-10">
													{link.children.map((section) => (
														<MegaMenu key={link.id + link.href} {...section} />
													))}
												</ul>
											) : (
												<ul className="p-4">
													{link.children?.map((child) => (
														<ListItem
															key={child.href}
															href={child.href}
															title={child.title}
															isIndex={child.isIndex}
														/>
													))}
												</ul>
											)}
										</NavigationMenuContent>
									</NavigationMenuItem>
								);
							})}
						</NavigationMenuList>
					</NavigationMenu>
				</div>
			</div>

			{/* TODO move to mobile-navigation */}
			<div className="flex items-center lg:hidden">
				<LoginLink
					href={user ? "/members/profile/" : null}
					className="flex flex-1 cursor-pointer items-center gap-1 text-white lg:hidden"
					menu={!!user}
				>
					<UserIcon className="text-white" />
					<span className="max-w-20 overflow-hidden text-ellipsis whitespace-pre">
						{user ? user?.first_name : "Login"}
					</span>
				</LoginLink>
				<SearchDialog {...{ subscription }}>
					<div className="search flex items-center gap-x-2 px-3 py-2 font-medium text-primary hover:text-gray-900">
						<span className="hidden">Search</span>
						<SearchIcon className="h-4 w-4" color="#fff" />
					</div>
				</SearchDialog>
			</div>
		</>
	);
};

export function NavigationSkeleton() {
	return (
		<div className="lg:w-3/6">
			<div className="hidden grid-cols-6 gap-x-6 pr-11 lg:grid">
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
				<Skeleton className="h-6 w-full rounded-full" />
			</div>

			<div className="grid grid-cols-7 items-center justify-end gap-1 lg:hidden">
				<Skeleton className="col-span-2 h-6 w-6 place-self-center rounded-full" />
				<Skeleton className="col-span-3 h-4 w-12 place-self-center rounded" />
				<Skeleton className="col-span-2 h-5 w-5 place-self-center rounded-full" />
			</div>
		</div>
	);
}

const ExaminePlusCTA = () => (
	<Tooltip>
		<TooltipTrigger>
			<SaleWrapper
				cta={
					<FreeTrialWrapper
						cta={
							<Link
								href="/plus/"
								className="mx-2.5 flex items-center space-x-2 whitespace-nowrap rounded-full bg-premium px-3 py-1.5 text-center font-bold text-primary transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2"
							>
								Examine+
							</Link>
						}
						fallback={<Skeleton className="mx-2.5 h-[36px] w-[200px] rounded-full" />}
					>
						<ExaminePlusLink trackDetails={{ trackFor: "trial", label: "Try Examine+ for free" }}>
							<span className="mx-2.5 flex items-center space-x-2 whitespace-nowrap rounded-full bg-premium px-3 py-1.5 text-center font-bold text-primary transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2">
								Try Examine+ for free
							</span>
						</ExaminePlusLink>
					</FreeTrialWrapper>
				}
			>
				<ExaminePlusLink trackDetails={{ trackFor: "sale", label: "Examine+ ON SALE" }}>
					<span className="mx-2.5 flex items-center space-x-2 whitespace-nowrap rounded-full bg-premium px-3 py-1.5 text-center font-bold text-primary transition-colors hover:bg-primary hover:text-white xl:px-5 xl:py-2">
						Examine+
						<Badge variant="cyan" className="ml-1.5">
							<EndsTonight dayBeforeCta="SALE ENDS TOMORROW" lastDay="LAST DAY OF SALE">
								ON SALE
							</EndsTonight>
						</Badge>
					</span>
				</ExaminePlusLink>
			</SaleWrapper>
		</TooltipTrigger>
		<TooltipContent className="z-1000">
			<p className="!bg-examine-yellow-premium !font-medium !leading-6 !text-examine-purple-700">
				Examine is entirely self-funded, with no third-party ads or ties to food and supplement
				companies.{" "}
				<span className="font-semibold">
					Examine+ unlocks the Examine Database, Study Summaries, and Supplement Guides.
				</span>
			</p>
		</TooltipContent>
	</Tooltip>
);

export default Navigation;

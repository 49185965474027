"use client";

import { useKeenSlider } from "keen-slider/react";
import { ArrowRight } from "lucide-react";
import Link from "next/link";
import { useState } from "react";

import UILink from "@/components/ui/link";
import { home } from "@/lib/data/home";
import { cn } from "@/lib/utils";
import autoplayPlugin from "@/lib/utils/autoplay-plugin";

const ExploreSlider = () => {
	const { explores } = home;

	const [created, setCreated] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [ref, slider] = useKeenSlider<HTMLDivElement>(
		{
			loop: true,
			breakpoints: {
				"(min-width: 640px)": {
					slides: {
						perView: 2,
						origin: "center",
						spacing: 10,
					},
				},
				"(min-width: 768px)": {
					slides: {
						perView: 2.3,
						origin: "center",
						spacing: 10,
					},
				},
				"(min-width: 1024px)": {
					disabled: true,
				},
			},
			slides: {
				perView: 1,
			},
			slideChanged(slider) {
				setCurrentSlide(slider.track.details.rel);
			},
			created() {
				setCreated(true);
			},
		},
		[autoplayPlugin]
	);

	return (
		<div id="explore-examine" className="home-section bg-white py-10">
			{/* heading */}
			<div className="heading px-6 text-center">
				<h2 className="font-lora font-medium lg:text-2xl xl:text-4xl">Explore Examine</h2>
				<p className="px-4 pt-4 text-gray-600 md:mx-auto md:w-3/4 md:px-0 xl:text-xl">
					<strong className="text-black">See what the evidence shows.</strong> Information on
					Examine is organized into 26 <UILink href="/categories/">health categories</UILink>{" "}
					covering hundreds of <UILink href="/conditions/">conditions</UILink>. Interventions like{" "}
					<UILink href="/supplements/">supplements</UILink> and{" "}
					<UILink href="/diets/">diets</UILink> are evaluated based on measurable changes in
					specific outcomes like <UILink href="/outcomes/ldl-c/">LDL</UILink> and{" "}
					<UILink href="/outcomes/blood-pressure/">blood pressure</UILink>.
				</p>
			</div>
			<div className="relative w-full py-8 md:py-10 lg:py-20">
				<div
					ref={ref}
					className={cn(
						"keen-slider lg:mx-auto lg:flex lg:w-11/12 lg:justify-between xl:w-4/5",
						created ? "visible" : "invisible"
					)}
				>
					{explores.map((item, index) => {
						const isActive =
							slider.current?.options?.disabled ||
							slider.current?.track?.details?.slides?.[index]?.abs ===
								slider.current?.track?.details?.abs;

						return (
							<div
								className={cn("keen-slider__slide space-y-1 p-6", !isActive ? "opacity-50" : "")}
								key={index}
							>
								<p className="font-semibold leading-7 xl:text-xl">{item.title}</p>
								<ul className="space-y-2 py-4 lg:space-y-3">
									{item.lists.map(({ name, link }, idx) => (
										<li key={idx}>
											<Link
												href={link}
												className="group inline-flex items-center space-x-2 -tracking-2 text-primary lg:space-x-3 lg:text-lg lg:hover:underline xl:text-xl"
											>
												<span className="grid size-4 shrink-0 place-content-center rounded-full border border-primary transition-colors duration-300 md:size-5 lg:group-hover:bg-primary lg:group-hover:text-white">
													<svg
														className="size-2 md:size-3"
														viewBox="0 0 11 10"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M0.00756836 4.65753C0.00756836 4.82329 0.0734165 4.98226 0.190627 5.09947C0.307837 5.21668 0.466808 5.28253 0.632568 5.28253H7.87382L5.19007 7.96503C5.13196 8.02314 5.08586 8.09212 5.05441 8.16805C5.02297 8.24397 5.00678 8.32535 5.00678 8.40753C5.00678 8.48971 5.02297 8.57108 5.05441 8.64701C5.08586 8.72293 5.13196 8.79192 5.19007 8.85003C5.24818 8.90814 5.31716 8.95423 5.39309 8.98568C5.46901 9.01713 5.55039 9.03332 5.63257 9.03332C5.71475 9.03332 5.79612 9.01713 5.87205 8.98568C5.94797 8.95423 6.01696 8.90814 6.07507 8.85003L9.82507 5.10003C9.88327 5.04197 9.92945 4.973 9.96096 4.89707C9.99247 4.82114 10.0087 4.73974 10.0087 4.65753C10.0087 4.57532 9.99247 4.49392 9.96096 4.41799C9.92945 4.34205 9.88327 4.27309 9.82507 4.21503L6.07507 0.465028C6.01696 0.406918 5.94797 0.360823 5.87205 0.329374C5.79612 0.297925 5.71475 0.281738 5.63257 0.281738C5.55039 0.281738 5.46901 0.297925 5.39309 0.329374C5.31716 0.360823 5.24818 0.406918 5.19007 0.465028C5.13196 0.523138 5.08586 0.592124 5.05441 0.668049C5.02297 0.743973 5.00678 0.825348 5.00678 0.907528C5.00678 0.989708 5.02297 1.07108 5.05441 1.14701C5.08586 1.22293 5.13196 1.29192 5.19007 1.35003L7.87382 4.03253H0.632568C0.466808 4.03253 0.307837 4.09838 0.190627 4.21559C0.0734165 4.3328 0.00756836 4.49177 0.00756836 4.65753Z"
															fill="currentColor"
														/>
													</svg>
												</span>
												<span>{name}</span>
											</Link>
										</li>
									))}
								</ul>
								<Link
									href={item.all}
									className="inline-flex items-center font-semibold -tracking-2 text-primary hover:underline lg:text-lg lg:leading-10"
								>
									View All <ArrowRight className="ml-2 size-4 stroke-4" />
								</Link>
							</div>
						);
					})}
				</div>

				{created && slider.current ? (
					<div className="flex items-center justify-center space-x-1.5 pt-12 lg:hidden ">
						{[...Array(slider.current.track.details.slides.length).keys()].map((idx) => (
							<button
								className={`block h-3 ${
									currentSlide === idx
										? "w-6 rounded-lg bg-primary transition-[width] duration-200"
										: "w-3 rounded-full bg-gray-400"
								}`}
								key={idx}
								onClick={() => slider.current?.moveToIdx(idx)}
								aria-label={`Slide ${idx + 1}`}
							/>
						))}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default ExploreSlider;
